import { Component, OnInit } from '@angular/core';
import { Subscription } from '../subscription';
import { SubscriptionService } from '../subscription.service';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare global {
    interface Window {
        dataLayer: any;
    }
}

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    subscriptionForm = new FormGroup({
        email2: new FormControl('', Validators.required)
    });

    subscription = new Subscription();
    submitted = false;

    constructor(private subscriptionService: SubscriptionService, private snackbar: MatSnackBar) {}

    ngOnInit(): void {
        console.log('===== ON INIT LOGIC====');
        window.addEventListener('message', (message) => {
            if (
                message.data === 'iFrameButtonClicked' &&
                message.origin === 'https://iframe-test-dev.herokuapp.com'
            ) {
                console.log('====== MESSAGE DATA: ' + message.data);
                console.log('====== MESSAGE ORIGIN: ' + message.origin);

                let dataLayer = window.dataLayer || (window.dataLayer = []);
                dataLayer.push({
                    event: 'iFrameButtonClicked' // GTM event set in dataLayer
                });
            }
        });
    }

    addSubscription() {
        this.submitted = true;
        this.save();
    }
    private save(): void {
        console.log(this.subscription);
        this.snackbar.open("You've been subscribed successfully!", null, {
            duration: 5000
        });
        this.subscriptionService.addSubscription(this.subscription).subscribe();
    }
}
