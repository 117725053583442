import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscription } from './subscription';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscriptionsUrl = '/api/subscriptions';  // URL to web api
  constructor(
    private http: HttpClient
  ) { }

  addSubscription (subscription: Subscription): Observable<Subscription> {
    return this.http.post<Subscription>(this.subscriptionsUrl, subscription, httpOptions);
  }
}
