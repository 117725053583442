import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
scrollToElement ($element): void {
  console.log($element);
  $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
}
}
