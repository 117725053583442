import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css'],
  providers: [NgbCarouselConfig]
})
export class TestimonialsComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = false;
  constructor(config: NgbCarouselConfig) {
    config.showNavigationArrows = false;
      config.showNavigationIndicators = true;
  }

  ngOnInit() {
  }

}
