import {
   MatSidenavModule,
   MatToolbarModule,
   MatButtonModule,
   MatIconModule,
   MatListModule,
   MatSnackBarModule,
   MatFormFieldModule,
   MatInputModule
    } from '@angular/material';
import {NgModule} from '@angular/core';

@NgModule({
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatFormFieldModule,
   MatInputModule
      ],
  exports: [
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatFormFieldModule,
   MatInputModule
  ],
})
export class MaterialModule { }
