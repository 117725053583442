import { Component, OnInit } from '@angular/core';
import { SFCase } from '../sfcase';
import { SFCaseService } from '../sfcase.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  sfcase = new SFCase();
  submitted = false;
  constructor(
    private sfcaseService: SFCaseService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
  }
  addSFCase() {
    this.submitted = true;
    this.save();
  }
  private save(): void {
    console.log(this.sfcase);
    this.snackbar.open('Thanks for your interest. The message has been sent successfully!', null, {
      duration: 5000
    });
    this.sfcaseService.addSFCase(this.sfcase)
        .subscribe();
  }
}
